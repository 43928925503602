import React from 'react'
import styled from 'styled-components'
import { CommentData } from '../data'
import ResponsiveSetup from '../Responsive/ResponsiveSetup';
import bgImage from '../assets/image15.jpg'
import house from '../assets/image8.jpg'


const Container = styled.div`

display: flex;
flex-direction: column;
`;
const ContainerReviews = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
gap:5rem;
`;
const Image = styled.img`
max-width:50%;

${ResponsiveSetup({
    display: 'none'
})}
`;

const ImageReview = styled.div`
display: flex;
flex-direction: column;
max-width: 50%;

h1{
    color: blue;
    font-size: 3rem;
};
h2{
    font-size:1.9rem;
};
${ResponsiveSetup({

    marginTop: '5rem',
    maxWidth: '100%',
    backgroundImage: `url(${bgImage})`,
    backgroundsize: 'cover',
    backgroundPosition: 'center'
})}
`;
const CommentContianer = styled.div`
    display: flex;
    max-width:100%;
    justify-content:center;
    flex-direction: row;
    padding:3rem 0rem 0rem 0rem;
    gap:2rem;
    ${ResponsiveSetup({
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0rem 0rem 0rem 0rem'

})}
`
const CommentCard = styled.div`
display: flex;
flex-wrap: wrap;
word-wrap: break-word;
    min-height:30rem;
    max-width:15rem;
    padding: 3rem;
    border-radius: .5rem;
    box-shadow:0.9rem 0.9rem 0.9rem 0.9rem rgba(0,0,0,0.16);
    img{
        height: 3rem;
        width:3rem;
        border-radius: 12rem;
    };
    ${ResponsiveSetup({
    marginTop: '4rem',
    minHeight: '20rem',
    maxWidth: '30rem',
})
    }
`;
const Accreditations = styled.div`
display: flex;
justify-content: center;
gap:5rem;
margin:5rem;
/* padding: 9rem 15rem 0rem 15rem; */
${ResponsiveSetup({
    flexDirection: 'column'
})}
`;
const Image1 = styled.img`
display:flex;
min-height: 25rem;
max-width: 50%;
${ResponsiveSetup({
    maxWidth: '100%'
})}
    
`;
const Desc = styled.div`

    display: flex;
    flex-direction: column;
    text-align: center;
    word-wrap: break-word;
    h1{
        font-size: 3rem;
    };
    p{
        font-size: 1.1rem;
    }
    ${ResponsiveSetup({
    maxWidth: '100%'
})}
`

const Reviews = () => {

    return <Container>
        <ContainerReviews>
            <Image src={bgImage} alt='house'></Image>

            <ImageReview>
                <h1>Reviews</h1>
                ⭐⭐⭐⭐⭐
                <h2>"Thrilled with EJV Decorating Services!  Their professionalism and skill shone through from the very beginning to the project's completion.  </h2>

                <h2> They made the entire process so easy and stress-free. I wouldn't hesitate to recommend them to anyone seeking top-notch painting services."</h2>
                <p>Tarun <br />
                    Web Developer</p>
            </ImageReview>
        </ContainerReviews>
        <CommentContianer>
            {CommentData.map((data) => {
                return <CommentCard key={data.id}>

                    <h2>{data.title}</h2>
                    ⭐⭐⭐⭐⭐
                    <p>{data.desc}</p>

                    <img src={data.img} alt='incoming-image' />
                    {data.user}

                </CommentCard>
            })}
        </CommentContianer>
        <Accreditations>
            <Image1 src={house} alt='house' />
            <Desc>
                <h1> Expertise</h1>
                <p>At EJV Decorating Services, we prioritize exceptional service and quality workmanship. Our dedication to exceeding client expectations is reflected in our strong commitment to professional excellence. We adhere to the highest standards in the industry. This ensures you can trust us to deliver exceptional results and ethical business practices throughout your project.</p>
            </Desc>
        </Accreditations>
    </Container >
}

export default Reviews
