import { Link } from "react-router-dom"
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import CollectionsIcon from '@mui/icons-material/Collections';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReviewsIcon from '@mui/icons-material/Reviews';
import BookIcon from '@mui/icons-material/Book';
import thermometer from '../src/assets/6.png'
import Cracks from '../src/assets/5.png'
import Home from '../src/assets/4.png'
import Plant from '../src/assets/3.png'
import Drop from '../src/assets/2.png'
import Security from '../src/assets/1.png'
import image1 from '../src/assets/image1.jpg';
import image2 from '../src/assets/image2.jpg';
import image3 from '../src/assets/image3.jpg';
import image4 from '../src/assets/image4.jpg';
import image5 from '../src/assets/image5.jpg';
import image6 from '../src/assets/image6.jpg';
import image7 from '../src/assets/image7.jpg';
import image8 from '../src/assets/image8.jpg';
import image9 from '../src/assets/image9.jpg';
import image10 from '../src/assets/image10.jpg';
import image11 from '../src/assets/image11.jpg';
import image12 from '../src/assets/image12.jpg';
import image14 from '../src/assets/image14.jpg';
import image15 from '../src/assets/image15.jpg';
import image16 from '../src/assets/image16.jpg';
import image17 from '../src/assets/image17.jpg';
import image18 from '../src/assets/image18.jpg';



export const slideData1 = [
    {
        id: 1,
        bg: "https://universalpainters.co.uk/wp-content/uploads/2023/09/external-wall-cotings.png",
        title: "External Painting",
        desc: "As a leading provider of External Wall Coatings and Painting services, Universal Painters is committed to delivering superior results for your property.",
        link: <Link to="" style={{ color: "white", textDecoration: 'none' }}>Learn more</Link>
    },
    {
        id: 2,
        bg: "https://universalpainters.co.uk/wp-content/uploads/2023/09/internal-paints.png",
        title: "Internal Insulation",
        desc: "At Universal Painters, we offer an innovative solution for internal insulation with our high-quality insulation paint.",
        link: <Link to="" style={{ color: "white", textDecoration: 'none' }}>Learn more</Link>
    },
    {
        id: 3,
        bg: "https://universalpainters.co.uk/wp-content/uploads/2023/09/background.png",
        title: "Thermal Cork",
        desc: "At Universal Painters, we specialise in thermal cork spray and cork rendering, offering exceptional solutions for your home’s exterior.",
        link: <Link to="" style={{ color: "white", textDecoration: 'none' }}>Learn more</Link>
    },
];
export const slideData2 = [
    {
        id: 1,
        bg: "https://universalpainters.co.uk/wp-content/uploads/2023/09/1-1.png",
        title: "K-Rend Silicone",
        desc: "Universal Painters is your local K-rend and silicone rendering specialists. With over 30 years of experience in the industry, we take pride in delivering professional and reliable services to our valued customers.",
        link: <Link to="" style={{ color: "white", textDecoration: 'none' }}>Learn more</Link>
    },
    {
        id: 2,
        bg: "https://universalpainters.co.uk/wp-content/uploads/2023/09/3-1-1.png",
        title: "Damp Solutions",
        desc: "Universal Painters is a leading provider of damp services, offering comprehensive solutions to address and resolve damp-related issues in your property. ",
        link: <Link to="" style={{ color: "white", textDecoration: 'none' }}>Learn more</Link>
    },
    {
        id: 3,
        bg: "https://universalpainters.co.uk/wp-content/uploads/2023/09/commercial-background1.png",
        title: "Commercial Contracts",
        desc: "Universal Painters is a trusted provider of intumescent painting services, offering comprehensive solutions to enhance fire safety in your property.",
        link: <Link to="" style={{ color: "white", textDecoration: 'none' }}>Learn more</Link>
    }

]

export const CardsData = [
    {
        id: 1,
        title: 'Expertise You Can Trust for Over 25 Years',
        desc: 'For more than three decades, EJV Decorating has built a reputation for exceptional service. We bring a wealth of knowledge and expertise to every project, confidently tackling even the most complex jobs.  This ensures exceptional results that consistently exceed your expectations.',
        img: <EmojiEventsIcon style={{ color: "blue", fontSize: "2.5rem", backgroundColor: "whitesmoke", borderRadius: "10rem", padding: "0.5rem" }} />
    },
    {
        id: 2,
        title: 'EJV Decorating Services is Insured',
        desc: 'At EJV Decorating Services, your trust matters. We are fully insured, providing peace of mind that any unforeseen incidents or damages will be covered. This comprehensive insurance ensures we can confidently take on projects of any size.',
        img: <WbSunnyIcon style={{ color: "blue", fontSize: "2.5rem", backgroundColor: "whitesmoke", borderRadius: "10rem", padding: "0.5rem" }} />
    },
    {
        id: 3,
        title: 'Certified Professionals',
        desc: 'At EJV Decorating Services, we believe in quality. Our team comprises highly skilled and certified professionals, ensuring your project receives the expertise and attention to detail it deserves. You can trust us to deliver exceptional results that meet the highest standards.',
        img: <HandshakeIcon style={{ color: "blue", fontSize: "2.5rem", backgroundColor: "whitesmoke", borderRadius: "10rem", padding: "0.5rem" }} />
    },

]
export const CommentData = [
    {
        id: 1,
        title: "House Decorating Done Right!",
        desc: "EJV Decorating Services were fantastic! Eric and his team were professional, clean, and incredibly tidy throughout the entire project. Their attention to detail was exceptional, and they even offered helpful suggestions when a few additional tasks arose. They were incredibly accommodating, even though these additions meant extra work for them.  I wouldn't hesitate to recommend EJV Decorating Services. Their workmanship is excellent, and their professionalism is top-notch.",
        img: "https://img.freepik.com/free-photo/portrait-successful-man-having-stubble-posing-with-broad-smile-keeping-arms-folded_171337-1267.jpg?size=626&ext=jpg&ga=GA1.1.1546980028.1708905600&semt=ais",
        user: "Bruce"
    },
    {
        id: 2,
        title: "Practical Solutions, Exceptional Results",
        desc: "EJV Decorating Services have become our go-to team for various projects. Their practical approach and helpfulness are invaluable. They are quick to respond and understand our needs, making the entire process smooth and efficient.  Erion has a wealth of knowledge and was able to expertly advise us on the exact work required, ensuring it fit comfortably within our budget.  They even provided valuable recommendations for subsequent stages like plastering and decorating, making the entire project seamless. We highly recommend EJV Decorating Services for their practical approach and exceptional results.",
        img: "https://hips.hearstapps.com/ell.h-cdn.co/assets/16/01/1600x1066/gallery-1451933912-scandal-1.jpg?resize=1200:*",
        user: "Lucia"
    },
    {
        id: 3,
        title: "Professionalism and Peace of Mind",
        desc: "Erion at EJV Decorating Services impressed us from the start. They were friendly, knowledgeable, and provided a competitive quote in writing within 24 hours of the initial consultation. The entire team was incredibly hardworking, but they also took the time to explain the decorating process and keep us updated throughout the project. The finished work looks fantastic, and the added benefit of a 20-year warranty provides great peace of mind. They didn't require any upfront payment, and we were happy to pay in full (via bank transfer) upon completion. Top marks! EJV Decorating Services is a superb company.",
        img: "https://img.freepik.com/free-photo/handsome-elderly-businessman-suit_144627-1175.jpg?size=626&ext=jpg",
        user: "Chris"
    },
]

export const ExperienceData = [
    {
        id: 1,
        desc: "30 Years Experience",

    },
    {
        id: 2,
        desc: "All Work 100% Guaranteed",

    },
    {
        id: 3,
        desc: "Fully Insured Company",

    },
    {
        id: 4,
        desc: "No Call Out Charges",

    },
    {
        id: 5,
        desc: "No Hidden Costs",

    },
    {
        id: 6,
        desc: "100% Recommend Us",

    },
]
export const ReviewData = [
    {
        id: 1,
        title: "Bedroom Decorated",
        desc: "Absolutely delighted with the bedroom transformation! EJV Decorating exceeded expectations. Will definitely use them again.",
        loc: "SW15 March 2024"
    },
    {
        id: 2,
        title: "Living Room Painted",
        desc: "Fantastic job painting our living room. EJV Decorating team were professional and efficient. Highly recommend!",
        loc: "N12 January 2024"
    },
    {
        id: 3,
        title: "Kitchen Refreshed",
        desc: "Kitchen looks amazing after EJV Decorating's work. Great service, friendly team. Would definitely hire again.",
        loc: "E4 October 2023"
    },
    {
        id: 4,
        title: "Hallway Transformed",
        desc: "So impressed with the hallway makeover. EJV Decorating did a brilliant job. Will recommend to neighbors.",
        loc: "SE22 September 2023"
    },
    {
        id: 5,
        title: "Bathroom Painted",
        desc: "Bathroom looks fresh and clean thanks to EJV Decorating. Excellent workmanship, would use again.",
        loc: "NW3 July 2023"
    },
    {
        id: 6,
        title: "Office Decorated",
        desc: "Office looks fantastic after EJV Decorating's work. Efficient and professional service. Will definitely recommend.",
        loc: "EC1 May 2023"
    },
]
export const FaqData = [
    {
        id: 1,
        question: " What services does EJV Decorating offer?",
        ans: <p>EJV Decorating is your one-stop shop for all your interior and exterior decorating needs. We offer a comprehensive range of services, including:
            <ul>
                <li>
                    Interior Painting: We transform your living or work environment with meticulous attention to detail. We offer color consultations, high-quality paints and finishes, and expert application techniques.
                </li>
                <li>
                    Exterior Painting: We refresh your home's facade or provide weatherproofing solutions for commercial buildings.
                </li>
                <li>
                    Wallpapering: Our team is skilled in hanging a variety of wallpapers, from traditional to contemporary styles.
                </li>

            </ul></p>
    },
    {
        id: 2,
        question: "What areas does EJV Decorating serve?",
        ans: `EJV Decorating proudly serves residential, commercial, and industrial clients across London and the Home Counties.`
    },
    {
        id: 3,
        question: "What sets EJV Decorating apart from other decorating companies?",
        ans: `At EJV Decorating, we prioritize exceptional service, meticulous attention to detail, and a commitment to exceeding your expectations. We use only the finest quality materials and employ a team of highly skilled and certified professionals. We also take pride in our clear communication and transparent pricing.`
    },
    {
        id: 4,
        question: "How long has EJV Decorating been in business?",
        ans: `EJV Decorating has been transforming spaces for over 25 years. Our founder, Erion Biba, brings a wealth of experience and expertise to every project.`
    },
    {
        id: 5,
        question: "How can I get a free quote?",
        ans: `EJV Decorating offers free consultations and quotes. Simply contact us by phone or email, and we'll be happy to discuss your project and provide a personalized estimate.`
    },
    {
        id: 6,
        question: " Does EJV Decorating offer any guarantees?",
        ans: `We stand behind our work and offer a guarantee on our services.  Please inquire about specific details during your consultation.`
    },
    {
        id: 7,
        question: "Can EJV Decorating help me choose the right paint color?",
        ans: `Absolutely! Our team members are experienced in color consultations and can help you select the perfect color palette to suit your style and space.`
    },
    {
        id: 8,
        question: "What should I do to prepare for my painting project?",
        ans: `EJV Decorating will walk you through everything you need to do to prepare for your project.  Generally, this may involve removing furniture from the room and covering any belongings that cannot be moved. We will handle the rest!`
    }
]

export const PnDData = [
    {
        id: 1,
        img: thermometer,
        title: "Improved Insulation",
        desc: "Did you know that EJV Decorating Services can help you improve your home's comfort and energy efficiency? We offer a variety of wall coatings with exceptional thermal properties. These coatings act as an extra layer of insulation, reducing heat transfer through your walls. This translates to cooler interiors during the summer and warmer spaces in the winter. The result? Significant energy savings as your reliance on heating and cooling systems decreases."
    },
    {
        id: 2,
        img: Cracks,
        title: "Enhanced Protection",
        desc: "Wall coatings provide an additional layer of protection for your home’s exterior surfaces, shielding them from weather elements such as rain, wind, and UV rays. This helps to prevent damage, cracking, and fading, ensuring your walls stay in good condition for longer."
    },
    {
        id: 3,
        img: Home,
        title: "Aesthetic and Stylish Finish",
        desc: "Wall coatings come in a wide range of colours and finishes, allowing you to transform the appearance of your home and give it a fresh, updated look. Whether you prefer a smooth or textured finish, there is a coating option to suit your style and enhance your home’s curb appeal."
    },
    {
        id: 4,
        img: Plant,
        title: "Low Maintenance",
        desc: "Once applied, wall coatings require minimal maintenance. They are easy to clean and resistant to dirt, grime, and pollutants. A simple wash or gentle pressure wash is usually sufficient to keep your walls looking clean and vibrant. This saves you time and effort on regular maintenance tasks."
    },
    {
        id: 5,
        img: Drop,
        title: "Moisture Protection",
        desc: "Wall coatings act as a barrier against moisture infiltration, preventing water damage, dampness, and mould growth. This is particularly beneficial for homes located in areas with high humidity or prone to heavy rainfall. The coatings create a waterproof seal, keeping your walls dry and protected."
    },
    {
        id: 6,
        img: Security,
        title: "High Durability",
        desc: "Wall coatings are designed to withstand the test of time. They are highly durable and resistant to wear and tear, ensuring that your walls maintain their appearance and integrity for years to come. This eliminates the need for frequent repainting or repairs, saving you time and money in the long run."
    },

];
export const BlogData = [
    {
        id: 1,
        bg: image12,
        title: 'Your Interior Decorating Specailist',
        Desc: `EJV Decorating, a leading provider of Decorating and painting services across London and the Home Counties, is dedicated to transforming and protecting your residential, commercial, or industrial property. Our high-performance coatings offer a multitude of benefits, making them a top choice for discerning property owners.

EJV Decorating's solutions go beyond aesthetics. These coatings act as a thermal barrier, keeping your building warm in winter and cool in summer, leading to significant energy cost savings and a comfortable year-round environment.  They're also formulated for exceptional durability, withstanding rain, wind, and UV rays to prevent damage, cracking, and fading, ensuring your property's exterior looks its best for years to come.

We understand visual appeal matters too. EJV Decorating offers a diverse range of colors and finishes, allowing you to customize the look of your building and potentially increase its market value.

But expertise is what sets EJV Decorating apart.  Our team's extensive knowledge ensures you receive the optimal coating solution for your specific needs. We meticulously apply only the finest quality materials from trusted brands to achieve a flawless finish that maximizes performance and longevity.

Whether you're looking to improve energy efficiency, enhance weatherproofing, or simply revamp your building's exterior, EJV Decorating is your trusted partner.  Contact us today for a free consultation and discover how our external wall coating solutions can revolutionize the look, feel, and energy efficiency of your property.`
    },
    {
        id: 2,
        bg: image9,
        title: 'Are Internal Decorating A Good Option For My Home?',
        Desc: `Transform your home's interior with EJV Decorating's premium wall finishes. Designed for residential and commercial spaces, our finishes enhance both aesthetics and functionality.

Protect your walls from everyday wear and tear with our durable finishes. Enjoy peace of mind with lasting protection against stains, marks, and fading.

Experience the benefits of enhanced interior comfort. Our finishes can contribute to better acoustics and insulation, creating a quieter and more energy-efficient space.

Beyond protection and comfort, our finishes offer a wide range of colors and textures to suit your style. Transform your home's appearance and create a desired atmosphere with a sleek, smooth, or textured finish.

At EJV Decorating, we prioritize quality and customer satisfaction. Our experienced team ensures professional application and meticulous attention to detail.

Discover the transformative power of interior wall finishes. Contact EJV Decorating today for a free consultation and let us enhance your home's beauty, functionality, and comfort.`
    }
]
export const MenuData = [
    {
        title: 'Home',
        path: '/',
        icon: <HomeIcon />,
        iconOpen: <ArrowDropDownIcon />,
        iconClose: <ArrowDropUpIcon />
    },
    {
        title: 'About',
        path: '/aboutUs',
        icon: <InfoIcon />,
        iconOpen: <ArrowDropDownIcon />,
        iconClose: <ArrowDropUpIcon />,
        subNav: [
            {
                title: 'FAQ',
                path: '/faqs',
                icon: <QuestionAnswerIcon />
            },
            {
                title: 'Reviews',
                path: '/reviews',
                icon: <ReviewsIcon />
            },
            {
                title: 'Blogs',
                path: '/blogs',
                icon: <BookIcon />
            }
        ]
    }, {
        title: 'Gallery',
        path: '/gallery',
        icon: <CollectionsIcon />
    }

]
export const GalleryImages = [
    {
        id: 1,
        image: image1
    },
    {
        id: 2,
        image: image2
    },
    {
        id: 3,
        image: image3
    },
    {
        id: 4,
        image: image4
    },
    {
        id: 5,
        image: image5
    },
    {
        id: 6,
        image: image6
    },
    {
        id: 7,
        image: image7
    },
    {
        id: 8,
        image: image8
    },
    {
        id: 9,
        image: image9
    },
    {
        id: 10,
        image: image10
    },
    {
        id: 11,
        image: image11
    },
    {
        id: 12,
        image: image12
    },
    {
        id: 14,
        image: image14
    },
    {
        id: 15,
        image: image15
    },
    {
        id: 16,
        image: image16
    },
    {
        id: 17,
        image: image17
    },
    {
        id: 18,
        image: image18
    },
]










