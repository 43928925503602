import React from 'react'
import styled from 'styled-components'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import ResponsiveSetup from '../Responsive/ResponsiveSetup';
import banner from '../assets/banner-image.png'

const Container = styled.div`
display:flex;
max-height: 25rem;
background-color: darkblue;
background-repeat: no-repeat;
color: white;
 img{
    width:50%;
    }
${ResponsiveSetup({
    marginTop: '5rem',
    maxHeight: '100rem',
})}

`
const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:50%;
    /* padding:0rem 5rem 0rem 2rem; */
    a{
        text-decoration: none;
        color:white;
        ${ResponsiveSetup({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5rem',
    width: '100%',
    backgroundColor: 'blue'
})}
    }
    ${ResponsiveSetup({
    padding: '0rem 0rem 0rem 0rem',

})}

`


const Banner = () => {
    return <Container>
        <Text>
            <h1>Need A Free Estimate?</h1>
            <p>Transform your space with EJV Decorating Services. We offer expert painting and decorating solutions tailored to your needs, whether it's your home, office, or any other property. With a keen eye for detail and a commitment to quality, we deliver exceptional results that exceed expectations.</p>
            <a href='tel:+447480252125' style={{ textDecoration: 'none', color: 'white' }}><PhoneEnabledIcon />Call Us Now</a>
        </Text>
        <img src={banner} alt='image' />
    </Container>
}

export default Banner
