import React from 'react'
import styled from 'styled-components'
import Layout from '../Layout'
import { PnDData, BlogData } from '../data'

const Contianer = styled.div`

    `
const TitleBgImg = styled.div`
    display: flex;
    height:25rem;
    width:98.9vw;
    background-image: url("https://universalpainters.co.uk/wp-content/uploads/2023/09/external-wall-cotings.png");
    background-repeat: no-repeat;
    background-size: cover;
    `
const Overlay = styled.div`
    display:flex;
    height:25rem;
        width:100vw;
        background-color: black;
        opacity: 0.5;
        color: white;
        justify-content: center;
        align-items: center;
        h1{
            font-size: 3.5rem;
        }
    `
const Content = styled.div`
    padding: 7rem 5rem 5rem 5rem ;
    overflow: visible;
    `
const Title = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    `
const CardsContainer = styled.div`
    padding:5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    `
const Cards = styled.div`
        padding:1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    `
const Card = styled.div`
    display: grid;
    padding:2.5rem;
    min-height:30rem;
    max-width:20rem;
    justify-content: center;
    border-radius: 1.5rem;

    &:hover{
    box-shadow:0px 204px 204px -60px rgba(0, 0, 0, 0.16);}
    `
const Image = styled.img`
        max-height: 10rem;
        max-width: 10rem;
    `
const Heading = styled.h3`
    font-size:1.5rem;
    `
const Desc = styled.p`
    font-size: large;
    color:rgb(142, 145, 170);
    `
const ExternalWall = styled.div`
    display: grid;
    /* max-height: 170rem; */
    grid-template-rows: repeat(auto-fill);
    /* max-width: 72rem; */
    /* padding-left: 11rem ; */

    `;
const BgImage = styled.div`
    min-height: 25rem;
    background-image: url(${props => props.data.bg});
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    display: grid;
    padding-left:4rem;
    justify-content: start;
    align-items: center;
    `;
const Blogs = () => {
    return <Layout>
        <Contianer>
            <TitleBgImg>
                <Overlay><h1>Painting And Decorating</h1></Overlay>
            </TitleBgImg>
            <Content>
                <Title>
                    <h1>External Wall Coating Specialists</h1>
                    <h3>What Are External Wall Coatings?</h3>
                </Title>
                <CardsContainer>
                    <Cards>
                        {PnDData.map((props) => {
                            return <Cards key={props.id}>
                                <Card>
                                    <Image src={props.img} />
                                    <Heading>{props.title}</Heading>
                                    <Desc>{props.desc}</Desc>
                                </Card>
                            </Cards>
                        })}
                    </Cards>
                </CardsContainer>

                <ExternalWall>
                    {
                        BlogData.map((props) => {
                            return <>

                                <BgImage data={props}> <h1>{props.title}</h1></BgImage>
                                <p style={{ fontSize: '1rem', lineHeight: '2rem' }}> {props.Desc}</p>
                            </>

                        })}

                </ExternalWall>
            </Content>
        </Contianer>
    </Layout >

}

export default Blogs
